import Link from 'next/link';
import Text from '@deprecated-ui/common/Text';
import Flex from '@deprecated-ui/common/Flex';
import color from '@deprecated-ui/_style/color';
import { useRouter } from 'next/router';
import If from 'global/ErrorHandlers/If';
import Image from 'global/Image';
import StockbitLogo from './StockbitLogo';
import { TextInfo, Wrapper } from './styled';

interface AuthCardLayoutProps {
  children: React.ReactNode;
  hasShadow?: boolean;
  withBackIcon?: boolean;
  withLoginLink?: boolean;
  withLogo?: boolean;
  withRegisterLink?: boolean;
  withTermsLink?: boolean;
  wrapperClassName?: string;
  backAction?: () => void | null;
}

function AuthCardLayout({
  children,
  hasShadow = true,
  withBackIcon = false,
  withLoginLink = false,
  withLogo = true,
  withRegisterLink = false,
  withTermsLink = false,
  wrapperClassName = '',
  backAction = null,
}: AuthCardLayoutProps): JSX.Element {
  const router = useRouter();

  const handleBackClick = () => {
    if (backAction) {
      backAction();
    } else {
      router.push('/');
    }
  };

  const showFooter = withLoginLink || withTermsLink || withRegisterLink;

  const shadowClass = hasShadow ? 'shadowed' : '';
  const wrapperClass = wrapperClassName || '';

  return (
    <Wrapper
      className={`${shadowClass} ${wrapperClass}`}
      data-cy="auth-card-layout"
    >
      <If condition={withLogo}>
        <Flex
          align="center"
          justify={withBackIcon ? 'space-between' : 'center'}
        >
          <If condition={withBackIcon}>
            <button
              className="login-back-icon"
              onClick={handleBackClick}
              type="button"
            >
              <Image
                src="/icon/chevron-left-gray.svg"
                width="10px"
                height="18px"
              />
            </button>
          </If>
          <StockbitLogo />
          <If condition={withBackIcon}>
            <div />
          </If>
        </Flex>
      </If>

      {children}

      <If condition={showFooter}>
        <Flex
          align="center"
          className="auth-text-container"
          direction="column"
          justify="center"
          // @ts-expect-error
          pt="20px"
        >
          <If condition={withLoginLink}>
            <TextInfo>
              Already have a Stockbit account?{' '}
              <Link href="/login" data-cy="auth-card-login-link">
                <Text as="a" size="12px">
                  Login
                </Text>
              </Link>
            </TextInfo>
          </If>

          <If condition={withTermsLink}>
            <TextInfo size="12px">
              By signing up you agree with our{' '}
              <Link href="/terms">
                <Text as="a" size="12px" data-cy="auth-card-terms-link">
                  Terms & Conditions.
                </Text>
              </Link>
            </TextInfo>
          </If>

          <If condition={withRegisterLink}>
            <Text align="center" color={color.black} size="12px">
              New to Stockbit?{' '}
              <Link href="/register">
                <Text as="a" size="12px" data-cy="auth-card-register-link">
                  Sign Up
                </Text>
              </Link>
            </Text>
          </If>
        </Flex>
      </If>
    </Wrapper>
  );
}

export default AuthCardLayout;
