import Text from '@deprecated-ui/common/Text';
import InputAntd from '@deprecated-ui/common/Input';
import color from '@deprecated-ui/_style/color';
import device from '@deprecated-ui/_style/device';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${color.light};
  border-radius: 4px;
  border: 1px solid ${color.gainsboro};
  box-sizing: border-box;
  margin: auto;

  &.shadowed {
    box-shadow: ${color.gainsboro} 0px 0px 10px;
  }

  @media ${device.mobileS} {
    padding: 20px 40px;
    width: 100%;
  }

  @media ${device.mobileL} {
    padding: 40px 70px;
    width: 450px;
  }

  input[type='tel'] {
    height: 36px !important;
  }

  button.login-back-icon {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  img.stockbit-logo {
    cursor: pointer;
  }
`;

const Input = styled(InputAntd).attrs({ p: '0' })`
  font-size: 20px;
`;

const DividerContainer = styled.div`
  align-items: center;
  background-color: ${color.gainsboro};
  display: flex;
  height: 1px;
  justify-content: center;
  margin: 29px 0;
  width: 100%;
`;

const Center = styled.div`
  background-color: ${color.light};
  padding: 0 20px;
`;

const TextInfo = styled(Text)`
  color: ${color.black};
  font-size: 12px;
  text-align: center;
`;

export { Center, DividerContainer, Input, TextInfo, Wrapper };
