import Link from 'next/link';
import Avatar from '@deprecated-ui/common/Avatar';
import Flex from '@deprecated-ui/common/Flex';
import color from '@deprecated-ui/_style/color';
import Image from 'global/Image';

const logo = '/images/logokotak.svg';

function StockbitLogo() {
  return (
    <Flex justify="center">
      <Link href="/">
        <Avatar size="large">
          <Image
            alt="Stockbit"
            data-cy="stockbit-logo"
            src={logo}
            style={{ background: color.highempahis, border: 'none' }}
            className="stockbit-logo"
          />
        </Avatar>
      </Link>
    </Flex>
  );
}

export default StockbitLogo;
